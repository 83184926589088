.totale-impact1 {
  position: relative;
  font-weight: 600;
}
.totale-impact-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.gram-co22 {
  position: relative;
}
.gram-co2-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 8px;
}
.frame-parent12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.impact-parent,
.impact2 {
  align-self: auto;
  width: 420px;
  display: flex;
  align-items: center;
}
.impact2 {
  background-color: #fff;
  flex-direction: row;
  padding: 10px 20px;
  justify-content: space-between;
}
.impact-parent {
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: 10px;
  color: #000;
  font-family: Roboto;
}
