.back-icon1 {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.back-container {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cibus2 {
  position: relative;
  font-weight: 600;
}
.cibus-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon7 {
  position: relative;
  width: 17.78px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}
.vector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}
.top-bar2 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 14px 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.maart-20231 {
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  width: 149px;
  height: 22px;
  flex-shrink: 0;
}
.maart-2023-wrapper {
  align-self: stretch;
  background-color: #e16d2d;
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
  align-items: center;
  justify-content: center;
}
.detailed-day,
.detailed-day-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.detailed-day-inner {
  align-self: stretch;
  background-color: #e16d2d;
  padding: 60px 0 0;
  align-items: center;
  font-size: 20px;
  color: #fff;
  font-family: Roboto;
}
.detailed-day {
  position: relative;
background-image:
    radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
    radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 50% 20%, rgba(231, 107, 152, 0.4), #dcdcdc 50%);

  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  align-items: flex-start;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Inter;
  
    overflow: auto;
}
@media screen and (max-width: 420px) {
  .top-bar2 {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
}
.Meegegeten-Ja{
    display: flex;
    background-color: #f66047;
    flex-direction: row;
    
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.95);
    width: 100%;
    padding: 0px 30px;
    
}
.text_below2 {
  margin: 20px;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recipeName{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
      font-weight: 600;
      font-family: Roboto;
      width: 100vw;
      margin-top: 20px;
}
.recipeName2 {
  text-align: center;
  position: relative;
  font-size: 18px;
}
