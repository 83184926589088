.vector-icon15 {
  position: relative;
  width: 17.78px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}
.home2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cibus8 {
  position: relative;
  font-weight: 600;
}
.name4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon16 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.top-bar8 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 14px 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.image-item,
.image1 {

  
  align-items: center;
  flex-shrink: 0;
}
.image-item {
  position: relative;
  max-width: 50%;
  height: 40%;
  max-height: 250px;
  margin: 10% 5% 5% 5%;
  overflow: hidden;
  object-fit: cover;
    display: flex;
  
      height: auto;
      justify-content: center;
      
}
.image1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #e5aaee;
width: 100%;
}
.gebruikersnaam,
.mijn-profiel {
  margin: 0;
  font-family: inherit;
  display: inline-block;
  width: 217px;
}
.mijn-profiel {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  font-weight: 600;
  height: 37px;
}
.gebruikersnaam {
  position: relative;
  font-size: inherit;
  font-weight: 700;
}
.explanationgebruikww {
  position: absolute;
  top: 49px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.gebruikersnaam1 {
  margin: 0;
  position: relative;
  display: inline-block;
  width: 217px;
}
.gebruikww {
  position: absolute;
  top: 49px;
  left: 217px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.group1 {
  flex: 1;
  position: relative;
  height: 89.5px;
}
.framing,
.mijnprofiel {
  width: 340px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.mijnprofiel {
  width: 380px;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
}
.bronvermelding-en-data,
.informatie-text {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.bronvermelding-en-data {
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.informatie-text {
  font-size: 14px;
  display: inline-block;
  height: fit-content;
  flex-shrink: 0;
  margin: 10px 10px;
}
.sources {
  width: 380px;
  padding: 20px;
  box-sizing: border-box;
  justify-content: flex-start;
  font-size: 20px;
  margin-bottom: 40px;
}
.mainframe1,
.profiel,
.profielinfo,
.sources {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profielinfo {
  align-self: stretch;
  background-color: #fff;
  
  flex-shrink: 0;
  overflow: hidden;
  padding: 10%;
  box-sizing: border-box;
  justify-content: center;
  gap: 20px;
}
.mainframe1,
.profiel {
  justify-content: flex-start;
}
.mainframe1 {
  align-self: stretch;
  flex: 1;
  font-size: 14px;
  font-family: Roboto;
}
.profiel {
  position: relative;
background-image:
    radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
    radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 50% 20%, rgba(231, 107, 152, 0.4), #dcdcdc 50%);

  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
    overflow-y: auto;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Roboto;
}
@media screen and (max-width: 420px) {
  .top-bar8 {
    top: 0;
    width: 100%;
  
    position: fixed;
    z-index: 8;
  }
  .profiel {
    width: auto;
    height: 780px;
  }
}

.filter-on-climate {
  position: relative;
  font-size: 13px;
  font-family: Roboto;
  color: #fff;
  text-align: center;
}
.filter,
.frame-parent9 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter {
  cursor: pointer;
  border: 0;
  align-items: center;
  padding: 10px 15px;
  background-color: #F66047;
  border-radius: 25px;
  flex-direction: row;
}
