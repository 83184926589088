.gebruik-de-persoonlijke1,
.welkom-bij-cibus {
  margin: 0;
  position: relative;
  display: inline-block;
  width: 275px;
  height: 39px;
  flex-shrink: 0;
}
.welkom-bij-cibus {
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.gebruik-de-persoonlijke1 {
  font-size: 13px;
  color: #9f9f9f;
}
.gebruikersnaam2,
.loginheader {
  height: 84px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.gebruikersnaam2 {
  border: 0;
  font-family: Roboto;
  font-size: 13px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.25);
  width: 300px;
  height: 41px;
  padding: 13px 25px 13px 15px;
  box-sizing: border-box;
  align-items: flex-start;
}
.logincontent,
.registerpart {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.logincontent {
  align-self: stretch;
  padding: 20px 0;
  text-align: center;
  font-size: 30px;
  color: #000;
  font-family: Roboto;
}
