.vector-icon5 {
  position: relative;
  width: 17.78px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}
.cibus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon6 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.het-persoonlijke-weekoverzicht,
.top-bar1 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.top-bar1 {
  background-color: #fff;
  flex-direction: row;
  padding: 14px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.het-persoonlijke-weekoverzicht {
  margin: 3;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  height: 23px;
  flex-shrink: 0;
  font-weight: normal;
}
.headeroverview,
.naam {
  display: flex;
  align-items: center;
}
.naam {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 35px;
  font-weight: 600;
  font-family: inherit;
  height: 24px;
  flex-shrink: 0;
}
.headeroverview {
  width: 352px;
  flex-direction: column;
  padding: 20px 10px 20px 10px;
  box-sizing: border-box;
  justify-content: flex-start;
  display: flex;
  gap:5px;

}
.image-illustration{
  float: right;
  margin-top: -80px;
    max-width: 120px;
    height: auto;
    margin-left: 96%;
}
.hier-komt-een {
  display: block;
  font-size: 16px;
  font-weight: lighter;
}
.hier-komt-een-container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-weight: 600;
  font: Roboto;
  font-weight: normal;
  
}
.weekfact {
  border-radius: 15px;
  background-color:  #B5AD9E;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 328px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.95;
  text-align: center;
  font-size: 10px;
  color: #fff;
  font-family: Roboto;
}
.jouw-impact-deze {
  margin: 0;
  position: relative;
  font-weight: 200;
  font-size: 16px;
}
.headerclimate {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: inherit;
  font-family: inherit;
}
.div {
  position: relative;
  font-weight: 600;
}
.amount {
  margin: 0;
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: inherit;
  font-family: inherit;
}
.gram-co2-bij {
  margin: 0;
  position: relative;
  font-size: 8px;
  font-weight: 300;
  display: inline-block;
  width: 108px;
  height: 10px;
  flex-shrink: 0;
}
.countingimpact {
  
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  font-size: 40px;
}
.gram-co2,
.vergelijkbare-huishoudens {
  margin: 0;
  position: relative;
  font-size: 14px;
  font-weight: lighter;
  
}
.gram-co2 {
  font-size: 16px;
  font-weight: bold;

}
.climateimpact,
.climatetext,
.comparablehouseholds {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.comparablehouseholds {
  justify-content: flex-start;
  gap: 10px;
  font-size: 8px;
  margin-top: 5px;
}
.climateimpact,
.climatetext {
  height: 145px;
  flex-shrink: 0;
  padding: 5px 0;
  box-sizing: border-box;
  justify-content: space-between;
  gap:2px;
}
.climateimpact {
  border-radius: 15px;
  background-color: #f66047;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 148px;
  height: 210px;
  padding: 10px 10px;
  justify-content: flex-start;
  opacity: 0.95;
  text-align: center;
  font-size: 10px;
  color: #fff;
  font-family: Roboto;
}
.p1 {
  margin: 0;
  position: relative;
  font-weight: 300;
  display: inline-block;
  width: 31px;
  flex-shrink: 0;
}
.group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.staat-gelijk-aan {
  margin: 0;
  position: relative;
  font-weight: 300;
  font-size: 16px;
}
.impactamount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}
.car-icon1 {
  position: relative;
  width: auto;
  height: 90px;
  flex-shrink: 0;
}
.iconcomparison {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.km {
  margin: 0;
  position: relative;
  font-weight: 300;
  font-size: 12px;
  display: inline-block;
  width: 100%;
  height: 16px;
  flex-shrink: 0;
  font-size: 16px;
}
.amount1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.met-de-auto {
  margin: 0;
  position: relative;
  font-weight: 300;
  display: inline-block;
  width: 89px;
  height: 16px;
  flex-shrink: 0;
}
.comparisoninfo,
.contentcomparisonframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1px;
}
.contentcomparisonframe {
  height: 178px;
  padding: 15px 0 0;
  box-sizing: border-box;
  gap: 10px;

}
.edit-icon {
  position: relative;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  overflow: hidden;
}
.editcomparison {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  justify-content: flex-end;
}
.comparisonsection,
.editcomparison,
.top2sections {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.comparisonsection {
  border-radius: 15px;
  background-color: #76A794;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  height: 248px;
  width: 148px;
  padding: 15px 5px 20px 15px;
  box-sizing: border-box;
  justify-content: center;
  opacity: 0.95;
  text-align: center;
  font-size: 10px;
  color: #fff;
  font-family: Roboto;
}
.top2sections {
  justify-content: flex-start;
  gap: 33px;
}
.aanbevolen-kcal-avondeten,
.p3 {
  margin: 0;
  position: relative;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  flex-shrink: 0;
}
.aanbevolen-kcal-avondeten {
  width: auto;
  height: auto;
}
.p3 {
  align-self: stretch;
  font-size: 40px;
  height: 50px;
  font-weight: bold;
}
.aanbevolen-kcal-avondeten-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.gemiddeld-kcal-deze,
.p4 {
  margin: 0;
  position: relative;
  font-weight: normal;
  display: inline-block;
  flex-shrink: 0;
  font-size: 16px;
}
.gemiddeld-kcal-deze {
  width: auto;
  height: auto;
}
.p4 {
  align-self: stretch;
  font-size: 40px;
  height: 58px;
  font-weight: bold;
}
.calorieinfo,
.moreinfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.calorieinfo {
  flex: 1;
  padding: 5px;
  gap: 3px;
}
.moreinfo {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.calories {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 148px;
  height: 248px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 10px 8px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  opacity: 0.95;
  text-align: center;
  font-size: 10px;
  color: #333333;
  font-family: Roboto;
}
.h1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.amount2 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.van-jouw-avondeten {
  margin: 0;
  position: relative;
  font-weight: 300;
  display: inline-block;
  width: 108px;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: lighter;
}
.infotext3 {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: 8px;
}
.percentagefive {
  border-radius: 15px;
  background-color: #E76B98;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 148px;
  height: 210px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 35px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  opacity: 0.95;
  text-align: center;
  font-size: 55px;
  color: #fff;
  font-family: Roboto;
}
.bottom2sections,
.fourbuttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.bottom2sections {
  width: 328px;
  flex-direction: row;
  gap: 32px;
  margin-top: -20px;
}
.fourbuttons {
  flex-direction: column;
}
.weekdoel-klimaatimpact-vlees {
  position: relative;
  color: #fff;
  font-size: 16px;
}
.averagewallet,
.weekdoeltext {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.weekdoeltext {
  margin: 0;
  align-items: flex-start;
  font-size: 16px;
  font-family: inherit;
}
.averagewallet {
  align-self: stretch;
  align-items: flex-end;
  font-size: 10px;
}
.headertext,
.mijn-klimaatimpact-vlees-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.headertext {
  height: 16px;
  flex-shrink: 0;
  padding: 0 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}
.mijn-klimaatimpact-vlees-wrapper {
  align-items: flex-start;
  justify-content: flex-start;
}
.frame,
.frame-parent5 {
  align-self: stretch;
}
.frame-parent5 {
  gap: 5px;
}
.frame-parent4,
.frame-parent5,
.weekdoel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.frame-parent4 {
  align-self: stretch;
  gap: 20px;
  font-size: 8px;
  color: #545454;
}
.weekdoel {
  border-radius: 15px;
  background-color: #30433B;
  width: 328px;
  padding: 20px 20px;
  box-sizing: border-box;
  gap: 15px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Roboto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}
.co21,
.maart-2023 {
  margin: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 22px;
  flex-shrink: 0;
}
.maart-2023 {
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  width: 149px;
}
.co21 {
  font-size: 13px;
  text-align: right;
  width: 61px;
}
.dateinfo,
.headerinfo {
  width: 294px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.dateinfo {
  border-radius: 15px 15px 0 0;
  background-color: #e16d2d;
  width: 328px;
  flex-direction: column;
  padding: 20px 15px;
  box-sizing: border-box;
  justify-content: flex-start;
  opacity: 0.95;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Roboto;
}
.ingredint {
  padding-bottom: 1px;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  padding-top: 20px;
}
.ingredient {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 13px;
  color: #545454;
  font-family: Roboto;
}
.dayinfo,
.ingredient-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ingredient-parent {
  align-self: stretch;
  border-radius: 0 0 15px 15px;
  background-color: #fff;
  padding: 10px 15px;
  justify-content: flex-start;
  gap: 10px;
  opacity: 0.95;
}
.dayinfo {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  padding: 0px 0;
  border-radius: 15px;
  justify-content: center;
}
.allcomponents {
  align-self: stretch;
  padding: 20px 0;
  justify-content: center;
  gap: 31px;
}
.allcomponents,
.background-overview,
.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}
.background-overview {
  align-self: stretch;
  padding: 60px 34px 120px;
  justify-content: center;
  gap: 0px;
  font-size: 14px;
  color: #333333;
  font-family: Roboto;
}
.overview {
  position: relative;
  background-image:
      radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
      radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71,0.4), rgba(220, 220, 220, 0), 50%, transparent),
      radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
      radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
      radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
      radial-gradient(closest-corner circle at 50% 20%, rgba(231,107,152,0.4), #dcdcdc 50%);
  
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Inter;
  height: 100vh;
    overflow-y: auto;
}
@media screen and (max-width: 420px) {
  .top-bar1 {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
  .het-persoonlijke-weekoverzicht,
  .ingredient {
    align-self: stretch;
    width: 250px;
    font-size: 16px;
  }
  .ingredient {
    height: auto;
  }
}
