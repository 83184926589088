.piechartoutlined-icon1 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}
.overzicht1 {
  position: relative;
  font-size: 13px;
  font-family: Roboto;
  color: #9f9f9f;
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16px;
  flex-shrink: 0;
}
.piechartoutlined-group {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 72.8px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.vector-icon21 {
  position: relative;
  width: 18.62px;
  height: 19.2px;
  flex-shrink: 0;
}
.vector-group {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 72.8px;
  height: 49.2px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.icon-fast-food-outline1,
.icon-person1,
.profiel2 {
  position: relative;
  width: 21.5px;
  height: 21.5px;
  flex-shrink: 0;
}
.icon-person1,
.profiel2 {
  width: 18.57px;
  height: 20px;
}
.profiel2 {
  font-size: 13px;
  font-family: Roboto;
  color: #F66047;
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16px;
}
.bottom,
.bottom-menu2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bottom-menu2 {
  flex: 1;
  background-color: #fff;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.25);
  padding: 16px;
  justify-content: space-between;
}
.bottom {
  align-self: stretch;
  height: 82px;
  flex-shrink: 0;
  justify-content: center;
}
