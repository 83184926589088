.b6,
.naamrecept {
  font-family: Roboto;
}
.naamrecept {
  border: 0;
  font-size: 13px;
  background-color: transparent;
  border-radius: 15px;
  width: 328px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.b6 {
  position: relative;
  font-size: 14px;
  color: #545454;
  text-align: center;
}
.min {
  cursor: pointer;
  border: 0;
  padding: 2px 10px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.b8,
.buttonplusmin1,
.minuten3 {
  display: flex;
  align-items: center;
}
.buttonplusmin1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
}
.b8,
.minuten3 {
  position: relative;
  width: 49px;
  height: 23px;
  flex-shrink: 0;
}
.minuten3 {
  width: 68px;
}
.minuten1,
.minuten2,
.naam-recept2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.minuten2 {
  align-self: stretch;
  align-items: center;
}
.minuten1,
.naam-recept2 {
  width: 420px;
  padding: 20px 3px 10px 0;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 20px;
}
.naam-recept2 {
  width: 396px;
  height: 159px;
  flex-shrink: 0;
  flex-direction: column;
  padding: 30px 33px;
  align-items: center;
  gap: 15px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Roboto;
}
@media screen and (max-width: 1200px) {
  .naamrecept {
    background-color: #fff;
  }
}
