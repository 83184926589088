.back-icon3 {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.back {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cibus5 {
  position: relative;
  font-weight: 600;
}
.name1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon11 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.datum9,
.top-bar5 {
  display: flex;
  align-items: center;
}
.top-bar5 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  padding: 14px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.datum9 {
  position: relative;
  font-weight: 600;
  width: 60px;
  flex-shrink: 0;
}
.date1 {
  align-self: stretch;
  background-color: #e16d2d;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  padding: 60px 30px 20px;
  align-items: flex-start;
  justify-content: center;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.95);
  font-family: Roboto;
}
.adding-data {
  position: relative;
  background: linear-gradient(266deg, #dcdcdc, #B5AD9E);
  width: 100%;
  height: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Inter;
}
@media screen and (max-width: 420px) {
  .top-bar5 {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
}
