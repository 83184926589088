.button7 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 159px;
  height: 29px;
  flex-shrink: 0;
}
.button6,
.gegevens-vergeten2 {
  cursor: pointer;
  border: 0;
  flex-shrink: 0;
}
.button6 {
  padding: 15px;
  background-color: #30433B;
  border-radius: 30px;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.25);
  width: 201px;
  height: 55px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.gegevens-vergeten2 {
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  font-family: Roboto;
  color: #f66047;
  text-align: center;
  display: inline-block;
  width: 212px;
  height: 24px;
}
.gegevens-vergeten-parent {
  height: 54px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.button-parent {
  align-self: stretch;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  gap: 17px;
}
.button-parent,
.login,
.loginframe {
  display: flex;
  justify-content: flex-start;
}
.loginframe {
  flex: 1;
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  padding: 70px 30px;
  align-items: center;
  gap: 32px;
}
.login {
  position: relative;
background-image:
    radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
    radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 50% 20%, rgba(231, 107, 152, 0.4), #dcdcdc 50%);

  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  flex-direction: row;
  padding: 200px 0 0;
  box-sizing: border-box;
  align-items: flex-start;
}
