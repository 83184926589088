.ingredient-group {
  background-color: #fff;
    padding: 10px 5px;
    margin: 20px;
    border: 1px solid transparent;
    border-radius: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 10px;
    width: auto;
}

.ingredient-group p {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  text-align: right;
  color: #545454;
  font-family: Roboto;
}

.kcal6 {
  text-align: right;
  word-wrap: break-word;
}
.organize {
      display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-content: center;
        width: 100%;
      }

#Category {
  grid-column: 1;
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;

  

}



#Category.Category1 {
  background-color: #d986ca;
}

#Category.Category2 {
  background-color: #ff5638;
}

#Category.Category3 {
  background-color: #24d967;
}

#Category.Category4 {
  background-color: #26afdd;
}

#Category.Category5 {
  background-color: #f0fb23;
}


#Ingredient {
  grid-column: 2;
  width:70px;
  word-wrap: break-word;

  }


#CalorieIngredient {
  grid-column: 3;
  width: 50px;
    word-wrap: break-word;
}

#ClimateImpactIngredient {
  grid-column: 4;
width: 50px;
  word-wrap: break-word;
}

#WeightIngredient {
  grid-column: 5;
width: 50px;
  word-wrap: break-word;
}
#SchijfIngredient {
  grid-column: 6;
width: 50px;
  word-wrap: break-word;
}

.ingredient6 {
  padding: 5px 15px;
  font-size: 12px;
  text-align: center;
  color: #545454;
  font-family: Roboto;
  align-items: top;
  display: flex;
  justify-content: center;
  display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 3px;
}
@media screen and (max-width: 420px) {
  .ingredient6 {
    align-self: stretch;
    width: auto;
    height: auto;
  }
}
