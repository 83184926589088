.back-icon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.back-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-wrapper,
.input-wrapper {
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 0px;
}

.select-wrapper {
  flex: 1;
  margin-right: 8px;
  font-family: Roboto;
  color: #333;
}
select {
  width: 100%;
}

.input-wrapper {
  width: 80px;
  margin-right: 4px;
  height: 38px;
}

.select-input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #333;
  font-family: Roboto;
}

.input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #333;
  border: none;
  outline: none;
  text-align: right;
  padding-right: 4px;
}
.cibus {
  position: relative;
  font-weight: 600;
  font-family: Roboto;
}
.cibus-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.date, .top-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.top-bar {
  background-color: #fff;
  padding: 14px 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
  font-family: Inter;
}
.date {
  background-color: #30433b;
  border: 1px solid transparent;
  padding: 80px 0px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.95);
  width: 100%;
  margin: 0 auto;
  display: flex;
    flex-direction: column;
}
.date-container{
  display: flex;
  flex-direction: column;
  background-color: #30433b;
  width: 500px;
  align-items: center;
    justify-content: center;
}
.text_below{
  margin-top: 10px;
  font-size: 12px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.text{
  font-size: 12px;
}
.text-number{
  font-size: 14px;
  font-weight: bold;
}
.b {
  position: relative;
}
.wrapper {
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 2px 10px;
  align-items: flex-start;
  justify-content: center;
}
.b2,
.frame-parent,
.personen {
  display: flex;
  align-items: center;
}
.frame-parent {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
}
.b2,
.personen {
  position: relative;
  width: 49px;
  height: 23px;
  flex-shrink: 0;
}
.personen {
  width: 68px;
}
.aantal-personen,
.parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.parent {
  align-items: center;
  text-align: left;
  color: #fff;
}
.aantal-personen {
  padding: 20px 3px 10px 0;
  align-items: flex-start;
  gap: 20px;
}
.info-icon,
.ingredinten {
  position: relative;
  flex-shrink: 0;
}
.ingredinten {
  margin: 0;
  font-weight: 700;
  display: inline-block;
  width: 83px;
  height: 16px;
}
.info-icon {
  width: 14px;
  height: 14px;
  overflow: hidden;
}
.frame-child,
.frame-item,
.ingredinten-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
}
.ingredinten-parent {
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  align-items: center;
}
.frame-child,
.frame-item {
  border: 0;
  font-family: Roboto;
  font-size: 13px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 10px 20px;
  align-items: center;
}
.frame-item {
  align-self: stretch;
  flex: 1;
  padding: 10px;
  justify-content: space-between;
}
.gram {
  margin: 0;
  position: relative;
}
.frame-parent1,
.frame-wrapper1 {
  width: 127px;
  flex-shrink: 0;
  flex-direction: row;
}
.frame-parent1 {
  height: 35px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 9px;
}
.frame-wrapper1 {
  justify-content: space-between;
}
.frame-container,
.frame-div,
.frame-wrapper1 {
  display: flex;
  align-items: center;
}
.frame-div {
  align-self: stretch;
  height: 35px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 0 33px;
  box-sizing: border-box;
  justify-content: center;
  gap: 14px;
}
.frame-container {
  width: 396px;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: 13px;
  color: #000;
  font-family: Roboto;
}
.button,
.button1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: 12px;
  font-family: Roboto;
  color: #fff;
  text-align: center;
}
.button {
  border-radius: 30px;
  background-color: #76a794;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.1);
  width: 201px;
  height: 34px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 10px;
}
.save-button{
  align-items: center;
  justify-content: center;
}
.frame-child8 {
  border: 0;
  font-family: Roboto;
  font-size: 13px;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 360px;
  height:35px;
  flex-direction: column;
  padding: 10px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vector-icon20 {
  position: relative;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}
.button-wrapper,
.frame-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button-wrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.frame-group {
  align-self: stretch;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  padding: 15px 30px;
  gap: 14px;
  text-align: left;
  color: #000;
  width: 100vw;
}
.button2,
.button3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button3 {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  text-align: center;
  width: 159px;
  height: 29px;
  flex-shrink: 0;
}
.button2 {
  border-radius: 30px;
  background-color: #e5aaee;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.1);
  width: 140px;
  height: 34px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
}
.aantal-personen-parent,
.opslaan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.opslaan {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.aantal-personen-parent {
  flex: 1;
  gap: 25px;
  opacity: 0.95;

}
.frame-wrapper {
  align-self: stretch;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
}
.editing,
.editing-inner,
.frame-wrapper {
  display: flex;
  align-items: flex-start;
}
.editing-inner {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
.editing {
  position: relative;
background-image:
    radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
    radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 50% 20%, rgba(231, 107, 152, 0.4), #dcdcdc 50%);

  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Roboto;
}
@media screen and (max-width: 420px) {
  .top-bar {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
}
.ingredinten-parent {
  display: flex;
  align-items: center;
}

.ingredinten-parent>div {
  margin-right: 10px;
}

.ingredient-select {
  flex: 1;
  margin-right: 10px;
}

.ingredient-select__control {
  height: 40px;
  border-radius: 5px;
}

.ingredient-select__single-value {
  font-size: 16px;
}

.ingredient-weight {
  display: flex;
  align-items: center;
}

.ingredient-weight input {
  height: 40px;
  width: 80px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  padding: 0 10px;
  font-size: 16px;
  text-align: right;
}

.ingredient-weight p {
  margin-left: 10px;
  font-size: 16px;
}

.toggle-button {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  background-color: #ccc;
  transition: background-color 0.2s;
  border-color: transparent;
  font-family: Roboto;
  font-size: 14px;
  color: #ffffff;
}

.toggle-button::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.2s;
}

.toggle-yes {
  background-color: #76a794;
}

.toggle-yes::before {
  transform: translateX(70px);
}

.toggle-no {
  background-color: #f66047;
}

.toggle-no::before {
  transform: translateX(3px);
}
.join-dinner-text{
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  color: #545454;
  margin-bottom: 10px;
  text-align: center;
}
.join-dinner-wrapper{
  display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
    
}
.joindinner-center{
    display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
      align-self: stretch;
}