.info-icon2,
.ingredinten1 {
  position: relative;
  flex-shrink: 0;
}
.ingredinten1 {
  margin: 0;
  font-weight: 700;
  display: inline-block;
  width: 83px;
  height: 16px;
}
.info-icon2 {
  width: 14px;
  height: 14px;
  overflow: hidden;
}
.infobutton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frame-child2,
.frame-child3,
.ingredient9 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ingredient9 {
  width: 354px;
  align-items: flex-start;
  gap: 10px;
}
.frame-child2,
.frame-child3 {
  border: 0;
  font-family: Roboto;
  font-size: 13px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 10px 20px;
  align-items: center;
}
.frame-child3 {
  align-self: stretch;
  flex: 1;
  padding: 10px;
  justify-content: space-between;
}
.gram2 {
  margin: 0;
  position: relative;
}
.frame-parent14 {
  width: 127px;
  height: 35px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 9px;
}
.frame-parent13,
.frame-wrapper3 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frame-wrapper3 {
  width: 127px;
  justify-content: space-between;
}
.frame-parent13 {
  align-self: stretch;
  height: 35px;
  padding: 0 33px;
  box-sizing: border-box;
  justify-content: center;
  gap: 14px;
}
.ingredientlist-inner {
  width: 396px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 13px;
  color: #000;
  font-family: Roboto;
}
.button10,
.button11 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button11 {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: 14px;
  font-family: Roboto;
  color: #fff;
  text-align: center;
}
.button10 {
  border-radius: 30px;
  background-color: #9d4571;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.1);
  width: 201px;
  height: 34px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 15px;
  box-sizing: border-box;
}
.button-container,
.ingredientlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.button-container {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.ingredientlist {
  align-self: stretch;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  padding: 15px 33px;
  gap: 14px;
  text-align: left;
  font-size: 14px;
  color: #000;
  font-family: Roboto;
}
