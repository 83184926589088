.vector-icon13 {
  position: relative;
  width: 17.78px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.home1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cibus7 {
  position: relative;
  font-weight: 600;
}
.name3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon14 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.addrecipe {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.frame-child1,
.top-bar7 {
  background-color: #fff;
  display: flex;
  box-sizing: border-box;
}
.top-bar7 {
  align-self: stretch;
  flex-direction: row;
  padding: 14px 16px;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.frame-child1 {
  border: 0;
  font-family: Roboto;
  font-size: 13px;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 328px;
  flex-direction: column;
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}
.filter-on-climate {
  position: relative;
  font-size: 13px;
  font-family: Roboto;
  color: #fff;
  text-align: center;
}
.filter2,
.frame-parent9 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter2 {
  cursor: pointer;
  border: 0;
  padding: 10px 20px;
  background-color: #F66047;
  border-radius: 25px;
  flex-direction: row;
}
.filter-on {
  background-color: #30433B;

}
.frame-parent9 {
  flex-direction: column;
  padding: 10px 0;
  gap: 20px;
}
.frame-parent8,
.recipes,
.recipes1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.recipes1 {
  align-items: center;
  gap: 35px;
}
.frame-parent8,
.recipes {
  background-image:
      radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
      radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
      radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
      radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
      radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
      radial-gradient(closest-corner circle at 50% 20%, rgba(231, 107, 152, 0.4), #dcdcdc 50%);
  
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.frame-parent8 {
  align-self: stretch;
  padding: 60px 33px 100px;
  align-items: center;
  gap: 15px;
}
.recipes {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  align-items: flex-start;
  text-align: left;
  font-size: 16px;
  color: #333333;
  font-family: Roboto;
}
@media screen and (max-width: 420px) {
  .top-bar7 {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
  .frame-child1 {
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    box-sizing: border-box;
  }
}
.season {
  display: flex;
  align-items: center;
  justify-content: center;
}

.seizoensgroenten {
  padding-left: 10px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #76A794;
}