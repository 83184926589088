.piechartoutlined-icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}
.overzicht {
  position: relative;
  font-size: 13px;
  font-family: Roboto;
  color: #9d4571;
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16px;
  flex-shrink: 0;
}
.piechartoutlined-parent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 72.8px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.dagboek,
.vector-icon19 {
  position: relative;
  width: 18.62px;
  height: 19.2px;
  flex-shrink: 0;
}
.dagboek {
  font-size: 13px;
  font-family: Roboto;
  color: #9f9f9f;
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16px;
}
.vector-parent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 72.8px;
  height: 49.2px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.icon-fast-food-outline,
.icon-person {
  position: relative;
  width: 21.5px;
  height: 21.5px;
  flex-shrink: 0;
}
.icon-person {
  width: 18.57px;
  height: 20px;
}
.bottom-menu,
.bottom-menu1 {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bottom-menu1 {
  flex: 1;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.25);
  padding: 16px;
  justify-content: space-between;
}
.bottom-menu {
  align-self: stretch;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}
@media screen and (max-width: 420px) {
  .bottom-menu {
    align-self: stretch;
    width: auto;
  }
}
