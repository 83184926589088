.vector-icon4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 8.9px;
  height: 8.9px;
}
.kruisbutton2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 8.9px;
  height: 8.9px;
  flex-shrink: 0;
}
.kruisje2 {
  width: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 3px;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-start;
}
.gegevens-vergeten1 {
  position: relative;
}
.gebruik-de-persoonlijke {
  margin: 0;
}
.gebruik-de-persoonlijke-container {
  align-self: stretch;
  position: relative;
  font-size: 13px;
}
.gegevens-vergeten3 {
  position: relative;
  background-color: #76A794;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 30px;
  
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  font-family: Roboto;
}
.save_image{
  width: 175px;
  padding-top: 40px;
}