.main-frame-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 396px;
  height: 844px;
  overflow: hidden;
}
.main-frame-parent {
  position: relative;
  background-color: #d9d9d9;
  width: 100%;
  height: 844px;
}
