.hallo-ik-ben {
  margin: 0;
  flex: 1;
  position: relative;
  font-weight: 300;
}
.chatgpt-text,
.gpt {
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatgpt-text {
  align-self: stretch;
  border-radius: 32px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  height: 44px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 10px 0 10px 10px;
}


.chat-message {
  background-color: #F66047;
  font-size: 13px;
  color: white;

  flex-direction: column;
  padding: 10px 20px;
  border-radius: 15px;
  margin: 20px 30px 20px 80px;
  word-break: break-all;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: right;
  width: 70%;
  
}

.chat-answer {
  background-color: white;
  font-size: 13px;
  color:black;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 15px;
  margin: 20px 60px 20px 40px;
  width: 70%;
  word-break: break-all;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: left;
}
.chat-message.text {
  color: white;
}



.chat-container {
  align-items: flex-end;
  width: 420px;
  flex-direction: column;
  justify-content: space-between;
  
  display: flex;
  margin-top: 150px;
  align-items: flex-start;
  justify-content: center;
  overflow-y: visible;
  margin-bottom: 50px;
}

.chat-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  position: fixed;
  bottom: 70px;
  
  box-sizing: border-box;
  width: 420px;
}


.chat-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 15px;
  border: none;
  background-color: #fff;
  max-width: 100%;
  white-space: pre-wrap;
  overflow: auto;
}

.chat-send-button {
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 15px;
  border: none;
  background-color: #000000;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}


