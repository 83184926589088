.recipe-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
}
.rowrecipe {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  gap: 20px;
  width: 420px;
  justify-content: center;
  align-items: center;
}


.recipecard.seasonal {
  border: 10px solid #76A794;
  border-radius: 24px;
  box-sizing: border-box;
  
}

.recipe-card {
  display: flex;
  flex-direction: column;
  
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  min-height: 100vh;
}


.recipe-card img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.recipe-details {
  padding: 20px;
}

.recipe-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.recipe-time {
  font-size: 14px;
  margin-bottom: 10px;
}

.recipe-co2 {
  font-size: 14px;
}


.recipe {
  position: relative;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 160px;
  height: 270px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
  align-items: flex-start;
  cursor: pointer;
  text-align: left;
  font-size: 11px;
  color: #000;
  font-family: Roboto;
  
  
}

.imagefood {
  position: relative;
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 150px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.imagefood-child {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.recipe-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 12.5px;
  box-sizing: border-box;
  border-radius: 0 0 15px 15px;
  background-color: #fff;
  height: 85px;
  flex-shrink: 0;

}
.infotimeimpact{
  padding: 0px 10px;
}
.text_content{
  padding: 0px 10px;
}

.recipe-title {
  margin: 0;
  position: relative;
  font-weight: 600;
  display: inline-block;
  width: 100%;
}

.recipe-subtitle {
  font-weight: 300;
  width: 100%;
}

.recipe-subtitle-small {
  font-size: 10px;
}

.recipe-title-small {
  font-size: 14px;
}
 
.receipeinfo {
  display: grid;
  grid-template-columns: 75% 25%;
  align-items: center;
  padding-bottom: 10px;
}

.text-container {
  grid-column: 1;
}

.circle3 {
  grid-column: 2;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #76A794;
}