.vector-icon8 {
  position: relative;
  width: 17.78px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}
.home {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cibus3 {
  position: relative;
  font-weight: 600;
}
.name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon9 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.add {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.top-bar3 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 14px 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.jouw-historieoverzicht {
  position: relative;
  display: flex;
  align-items: center;
  width: 229px;
  height: 23px;
  flex-shrink: 0;
  color: #333333;
  font-size: 20px;
  font-weight: 600;
}
.header {
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.maart-20232 {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  text-align: left;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 22px;
  flex-shrink: 0;
}
.contentdatum,
.datum1 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contentdatum {
  margin: 0;
  width: 294px;
  justify-content: flex-start;
}
.datum1 {
  border-radius: 15px;
  background-color: #30433b;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  box-sizing: border-box;
  width: 328px;
  height: 43px;
  padding: 20px 15px;
  justify-content: space-between;
  opacity: 0.95;
}
.dates,
.diary,
.mainframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.dates {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.diary,
.mainframe {
background-image:
    radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
    radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 50% 20%, rgba(231, 107, 152, 0.4), #dcdcdc 50%);

  background-repeat: no-repeat;
  background-attachment: fixed;
}
.mainframe {
  align-self: stretch;
  padding: 70px 34px 100px;
  gap: 32px;
  font-size: 14px;
  color: #fff;
  font-family: Roboto;
}
.diary {
  position: relative;
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Inter;
  height: 100vh;
    overflow-y: auto;
}
@media screen and (max-width: 420px) {
  .top-bar3 {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
}
.infotext {
  font-size: 10px;
  color: #545454;
  text-align: center;
}

.category {
  display: flex;
  flex-direction: column;
  padding: 10px;

  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 70px;
border: #30433b;
}

.categoryinfo{
display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 10px;
}
.seasons-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
 
}

.circles {
  width: 30px;
  height: 30px;
  border-radius: 50%;
border: 0px solid#30433b;

  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.circles.red {
  background-color: #ff5638;
    
  
}

.circles.green {
  background-color: #24d967;
}

.circles.blue {
  background-color: #f0fb23;
}

.circles.orange {
  background-color: #26afdd;
}

.circles.purple {
  background-color: #d986ca;
}