.vector-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 8.9px;
  height: 8.9px;
}
.kruisbutton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 8.9px;
  height: 1px;
  flex-shrink: 0;
}
.kruisje {
  align-self: stretch;
  height: 17px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 3px;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-start;
}
.kies-jour-vergelijking {
  position: relative;
  font-weight: 600;
}
.kies-jour-vergelijking-wrapper {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
.vector-icon2 {
  position: relative;
  width: 80px;
  height: 72px;
  flex-shrink: 0;
}
.toilet-paper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  align-items: flex-start;
  justify-content: flex-start;
}
.toiletpaper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.beer-icon {
  position: relative;
  width: 62.5px;
  height: 65px;
  flex-shrink: 0;
}
.firstlinecomaprison {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 62px;
}
.car-icon,
.laundry-icon {
  position: relative;
  width: 73px;
  height: 65px;
  flex-shrink: 0;
}
.laundry-icon {
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.options,
.secondlinecomparison {
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondlinecomparison {
  align-self: stretch;
  flex-direction: row;
  gap: 51px;
}
.options {
  flex-direction: column;
  gap: 20px;
}
.button5 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  text-align: center;
  width: 159px;
  height: 29px;
  flex-shrink: 0;
}
.button4,
.button5,
.opslaan1,
.option {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button4 {
  align-self: stretch;
  border-radius: 30px;
  background-color: #F66047;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  padding: 10px 15px;
}
.opslaan1,
.option {
  flex-direction: column;
}
.opslaan1 {
  cursor: pointer;
  border: 0;
  padding: 10px 0;
  background-color: transparent;
}
.option {
  position: relative;
  background-color: #76A794;
  width: 360px;
  height: 450px;
  padding: 30px 20px;
  box-sizing: border-box;
  gap: 10px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-family: Roboto;
}
