.back-icon2 {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.back-frame {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cibus4 {
  position: relative;
  font-weight: 600;
}
.cibus-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon10 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.top-bar4 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 14px 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
  font-family: Inter;
}
.rectangle-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  flex-shrink: 0;
  object-fit: cover;

}

.recipe-image {
  width: 100%;
  height: auto;
  max-width: 420px;
  max-height: 237px;
  object-fit: cover;
  margin-top: 10px;
}

.recipes-content-inner {
  width: 420px;
  height: 237px;
  flex-shrink: 0;
  display: flex;
  
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}
.div1,
.g-co2 {
  position: relative;
  font-weight: 600;
    display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 200px;
  height: 13px;
  flex-shrink: 0;
}
.g-co2 {
  width: 28px;
}
.parent1 {
  width: 380px;
  
  padding: 0 20px;
  box-sizing: border-box;
    display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
}
.minuten {
  position: relative;
  font-weight: 600;
  display: inline-block;
  width: 50px;
  height: 13px;
  flex-shrink: 0;
}
.frame-parent7,
.naam-recept {
  align-self: auto;
  
  padding: 10px 20px;
  width: 420px;
      display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
}
.frame-parent7 {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;

  gap: 4px;
  font-size: 14px;
  color: #000;
}
.naam-recept {
  background-color: #30433b;
  display: flex;
  
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.95);
}
.bereidingswijze {
  position: relative;
}
.header-bereidingswijze {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.bereidingswijze-tekst {
  margin: 0;
  position: relative;
  font-size: 13px;
  display: inline-block;
  width: 100%;
}
.bereiding,
.recipes-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.bereiding {
  width: 420px;
  padding: 0px 20px;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 0px;
  font-size: 18px;
 
}
.stappen{
  font-size: 16px;
  margin: 0px 10px 10px 0px;
  max-width: 320px;
  font-weight: 200;
}
.recipes-content {
  position: relative;
background-image:
    radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
    radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
    radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
    radial-gradient(closest-corner circle at 50% 20%, rgba(231, 107, 152, 0.4), #dcdcdc 50%);

  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 100%;
  overflow: auto;
  align-items: center;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Roboto;
}
@media screen and (max-width: 420px) {
  .top-bar4 {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
}
.ingredient-group2 {
  background-color: #fff;
  padding: 10px 5px;
  margin: 20px;
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  gap: 10px;
  width: fit-content;
  
}
.organize2{
  background-color: #F66047;
    padding: 10px 15px;
    margin: 30px;
    border: 1px solid transparent;
    border-radius: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 10px;
    width: 100vw;
    color:#fff;
}
.ingredient62{
  padding: 5px 15px;
    font-size: 12px;
    text-align: right;
    color: #545454;
    font-family: Roboto;
    align-items: top;
    display: flex;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

}

.kcal62 {
  text-align: right;
  word-wrap: break-word;
  font-size: 14px;
    color: #000;
}

#Ingredient2 {
  grid-column: 2;
  width: 120px;
  word-wrap: break-word;
  text-align: left;

}



#ClimateImpactIngredient2 {
  grid-column: 4;
  width: 60px;
  word-wrap: break-word;
  color: #30433b;
}

#WeightIngredient2 {
  grid-column: 1;
  width: 50px;
  word-wrap: break-word;
}
#seasonal {
  grid-column: 3;
  width: 20px;
  word-wrap: break-word;
  color: #30433b;
}


.circle {
  width: 15px;
  height: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;

}

.buttons_click {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
 
}


.toggle-button2 {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: black;
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.toggle-button2.active {
  color: #F66047;
  font-weight: bold;
  font-size: 16px;
}
.co2_icon{
  width: 15px;
}