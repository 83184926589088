.vector-icon17 {
  position: relative;
  width: 17.78px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}
.vector-container {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cibus9 {
  position: relative;
  font-weight: 600;
}
.cibus-wrapper2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon18 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.je-kan-aan,
.top-bar9 {
  display: flex;
  align-items: center;
}
.top-bar9 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  padding: 14px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.je-kan-aan {
  margin: 0;
  position: relative;
  font-weight: 300;
  justify-content: center;
  width: 90%;
  
}
.infotext1 {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 418px;
  flex-direction: column;
  padding: 30px 30px;
  box-sizing: border-box;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: #000;
  font-family: Roboto;
  position: fixed;
  top:20px;
  z-index: 1;
}
.allcontent,
.chatvraag,
.infotext1 {
  display: flex;
  align-items: center;
  width: 100%;


}
.allcontent {
  align-self: stretch;
  flex-direction: column;
  padding: 100px 0;
  justify-content: flex-start;
  overflow-y: auto;
    height: 100vh;
}
.chatvraag {
  border: 0;
  background-color: #fff;
  font-family: Roboto;
  font-size: 13px;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.25);
  width: 80%;
  flex-shrink: 0;
  flex-direction: row;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
}
.send-icon {
  position: relative;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  overflow: hidden;
}
.sending {
  cursor: pointer;
  border: 0;
  padding: 10px;
  background-color: #30433B;
  align-self: stretch;
  width: 55px;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
}
.chat,
.sending,
.textinput {
  display: flex;
  align-items: center;

}
.textinput {
  width: 418px;
  flex-direction: row;
  justify-content: center;
}
.chat {
  position: relative;
  background-image:
      radial-gradient(closest-corner circle at 40% 70%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
      radial-gradient(closest-corner circle at 40% 50%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
      radial-gradient(closest-corner circle at 65% 10%, rgba(183, 176, 120, 0.4), rgba(220, 220, 220, 0), 60%, transparent),
      radial-gradient(closest-corner circle at 60% 90%, rgba(246, 96, 71, 0.4), rgba(220, 220, 220, 0), 50%, transparent),
      radial-gradient(closest-corner circle at 50% 80%, rgba(229, 170, 238, 0.4), rgba(220, 220, 220, 0) 50%, transparent),
      radial-gradient(closest-corner circle at 50% 20%, rgba(231, 107, 152, 0.4), #dcdcdc 50%);
  
    background-repeat: no-repeat;
    background-attachment: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Inter;
}
@media screen and (max-width: 1200px) {
  .infotext1 {
    position: fixed;
    top: 48;
  }
}
@media screen and (max-width: 420px) {
  .top-bar9 {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
  .allcontent {
    width: auto;
    
    scroll-behavior: auto;
  }
  .textinput {
    align-items: center;
    justify-content: flex-end;
  }
}
