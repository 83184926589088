.back-icon4 {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.back1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cibus6 {
  position: relative;
  font-weight: 600;
}
.name2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vector-icon12 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.top-bar6 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 14px 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.image,
.image-child {
  align-self: stretch;
  height: 237px;
  flex-shrink: 0;
}
.image-child {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.g-co21,
.p6 {
  margin: 0;
  position: relative;
  font-weight: 600;
  display: inline-block;
  width: 21px;
  height: 13px;
  flex-shrink: 0;
}
.g-co21 {
  width: 28px;
}
.impact,
.impacttext {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: flex-start;
  justify-content: flex-start;
}
.impact {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  padding: 10px 20px;
}
.bereidingswijze1 {
  position: relative;
}
.bereidingswijze2,
.header-bereidingswijze1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.header-bereidingswijze1 {
  margin: 0;
}
.bereidingswijze2 {
  border: 0;
  background-color: #fff;
  font-family: Roboto;
  font-size: 13px;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  width: 328px;
  padding: 10px;
  box-sizing: border-box;
}
.header-bereidingswijze-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9px;
  font-size: 14px;
  color: #fff;
}
.button9 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  text-align: center;
  justify-content: center;
  width: 159px;
  height: 29px;
  flex-shrink: 0;
}
.button8,
.button9,
.main1,
.opslaan2 {
  display: flex;
  align-items: center;
}
.button8 {
  align-self: stretch;
  flex: 1;
  border-radius: 30px;
  background-color: #e16d2d;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  padding: 15px;
  justify-content: center;
}
.main1,
.opslaan2 {
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}
.opslaan2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 42px;
}
.main1 {
  width: 420px;
  height: 612px;
  padding: 0 0 30px;
  box-sizing: border-box;
  gap: 25px;
  opacity: 0.95;
}
.main {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  align-items: flex-start;
  justify-content: center;
  font-size: 10px;
  color: #000;
  font-family: Roboto;
}
.adding-recipe {
  position: relative;
  background: linear-gradient(266deg, #dcdcdc, #B5AD9E);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #545454;
  font-family: Inter;
}
@media screen and (max-width: 420px) {
  .top-bar6 {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 8;
  }
}
