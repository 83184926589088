.b3 {
  position: relative;
  font-size: 14px;
  font-family: Roboto;
  color: #545454;
  text-align: center;
}
.frame-button {
  cursor: pointer;
  border: 0;
  padding: 2px 10px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.b5,
.buttonplusmin,
.personen1 {
  display: flex;
  align-items: center;
}
.buttonplusmin {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
}
.b5,
.personen1 {
  position: relative;
  width: 49px;
  height: 23px;
  flex-shrink: 0;
}
.personen1 {
  width: 68px;
}
.aantal-personen1,
.persons {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.aantal-personen1 {
  padding: 20px 3px 10px 0;
  align-items: flex-start;
  gap: 20px;
}
.button13 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: Roboto;
  color: #fff;
  text-align: center;
  justify-content: center;
  width: 159px;
  height: 29px;
  flex-shrink: 0;
}
.button12,
.button13,
.content,
.opslaan3 {
  display: flex;
  align-items: center;
}
.button12 {
  align-self: stretch;
  flex: 1;
  border-radius: 30px;
  background-color: #e16d2d;
  box-shadow: 0-5px 10px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  padding: 15px;
  justify-content: center;
}
.content,
.opslaan3 {
  flex-direction: column;
  justify-content: flex-start;
}
.opslaan3 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 42px;
  flex-shrink: 0;
}
.content {
  flex: 1;
  padding: 0 0 30px;
  gap: 25px;
  opacity: 0.95;
}
.main2 {
  width: 420px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Roboto;
}
