.vector-icon3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 8.9px;
  height: 8.9px;
}
.kruisbutton1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 8.9px;
  height: 8.9px;
  flex-shrink: 0;
}
.kruisje1 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 3px;
  align-items: flex-end;
  justify-content: flex-start;
}
.privacy1 {
  position: relative;
  font-weight: 600;
}
.om-deel-te {
  align-self: stretch;
  position: relative;
  font-size: 13px;
}
.privacy {
  position: relative;
  background-color: #fff;
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-family: Roboto;
}
