.hier-komt-een1 {
  display: block;
}
.hier-komt-een-container1 {
  margin: 0;
  position: relative;
  font-weight: 600;
  display: inline-block;
  width: 308px;
}
.hier-komt-een-feitje-van-de-we-wrapper {
  align-self: stretch;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
}
.edit-icon1 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}
.edit {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.vector-icon20 {
  position: relative;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}
.icon,
.trash {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.trash {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  justify-content: center;
}
.icon {
  align-self: stretch;
  background-color: #fff;
  padding: 10px 20px;
  justify-content: flex-end;
  gap: 6px;
}
.totale-impact {
  position: relative;
  font-weight: 600;
}
.totale-impact-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.gram-co21 {
  position: relative;
}
.gram-co2-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 8px;
}
.frame-parent11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.frame-parent10,
.impact1 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.impact1 {
  background-color: #fff;
  flex-direction: row;
  padding: 10px 20px;
  justify-content: space-between;
  text-align: left;
  color: #000;
}
.frame-parent10 {
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  font-size: 10px;
  color: #545454;
  font-family: Roboto;
}
